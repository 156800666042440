import type { DefaultSeoProps } from "next-seo";
import { DEFAULT_DOC_TITLE, DEFAULT_META_DESCRIPTION } from "src/constants";

let pageUrl;

if (typeof window !== "undefined") {
  pageUrl = window.location.origin + window.location.pathname;
}

const DEFAULT_SEO: DefaultSeoProps = {
  title: DEFAULT_DOC_TITLE,
  description: DEFAULT_META_DESCRIPTION,
  openGraph: {
    type: "website",
    locale: "en_IE",
    site_name: DEFAULT_DOC_TITLE,
  },
  twitter: {
    handle: "@handle",
    site: "@site",
    cardType: "summary_large_image",
  },
  canonical: pageUrl,
};

export default DEFAULT_SEO;
