import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons/faCircleNotch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import useChangingRoutes from "src/hooks/useChangingRoutes";

const Spinner = () => {
  const changingRoutes = useChangingRoutes();

  return (
    <Transition
      as={Fragment}
      show={changingRoutes}
      enter="transform transition duration-[400ms]"
      enterFrom="opacity-0 rotate-[-120deg] scale-50"
      enterTo="opacity-100 rotate-0 scale-100"
      leave="transform duration-200 transition ease-in-out"
      leaveFrom="opacity-100 rotate-0 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div className="fixed w-full h-full inset-0  flex justify-center items-center z-50">
        <div>
          <FontAwesomeIcon className="fa-spin" icon={faCircleNotch} size="4x" />
        </div>
      </div>
    </Transition>
  );
};

export default Spinner;
