import BKTLogo from "public/BKT_Dark.svg";
import BKTPlaceLogo from "public/BKT_PLACE_Dark.svg";

export enum brands {
  PLACE = "place",
  BRIVITY = "generic",
}

export const BRIVITY_FAVICON = "/brivityFavicon.ico";
export const PLACE_FAVICON = "/favicon.ico";

export const DEFAULT_DOC_TITLE = "Realty Careers from Place and Brivity";
export const DEFAULT_META_DESCRIPTION = "Job postings from Place and Brivity";
export const DEFAULT_FAVICON = BRIVITY_FAVICON;

// TODO: decide if we want to have separate cache times, or if same time is ok for both:
export const MAX_CACHE_AGE_TEAM_SECS = 60; // 1 minute
export const TEAM_SECS_STALE_OK = MAX_CACHE_AGE_TEAM_SECS + 10; // 10 seconds extra to re-evaluate (api call time)
export const MAX_CACHE_AGE_JOB_SECS = 60; // 1 minute
export const JOB_SECS_STALE_OK = MAX_CACHE_AGE_JOB_SECS + 10; // 10 seconds extra to re-evaluate (api call time)

// TODO: if there is a "real" svg type, let's use it. can't find one yet. (there must be....)
export interface ISvgProps {
  width: number;
  height: number;
  src: string;
}

export interface IDomainConfig {
  brand: brands;
  logo: ISvgProps;
}

export interface IDomainConfigDict {
  [key: string]: IDomainConfig;
}

// main domains here:
const domains: IDomainConfigDict = {
  "careers.realtypostings.com": {
    brand: brands.PLACE,
    logo: BKTPlaceLogo,
  },
  "jobs.realtypostings.com": {
    brand: brands.BRIVITY,
    logo: BKTLogo,
  },
};

export const brandConfig = {
  [brands.PLACE]: {
    logo: BKTPlaceLogo,
  },
  [brands.BRIVITY]: {
    logo: BKTLogo,
  },
};

export default domains;

// add aliases and localhost pointers here:
export const aliases: { [key: string]: string } = {
  // production aliases
  "jobs.hire.realestate": "jobs.realtypostings.com",
  "careers.hire.realestate": "careers.realtypostings.com",

  // staging aliases
  "staging.hire.realestate": "jobs.realtypostings.com",
  "staging-place.hire.realestate": "careers.realtypostings.com",
  "staging.realtypostings.com": "jobs.realtypostings.com",
  "staging-place.realtypostings.com": "careers.realtypostings.com",

  // local dev, switch these around as needed to target each domain config:
  // "localhost:3000": "careers.realtypostings.com",
  "localhost:3000": "jobs.realtypostings.com",
};
