import { library } from "@fortawesome/fontawesome-svg-core";

/**
 * @note please import icons in the correct location based on the icon type and in alphabetical (ascending) order
 */

// Solid → icon="..."
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretUp } from "@fortawesome/pro-solid-svg-icons/faCaretUp";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons/faCircleNotch";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons/faSpinner";
import { faPaperPlane } from "@fortawesome/pro-solid-svg-icons/faPaperPlane";

// Brands → icon={["fab", "..."]}
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";

// Regular → icon={["far", "..."]}
import { faFileAlt } from "@fortawesome/pro-regular-svg-icons/faFileAlt";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons/faEnvelope";

library.add(
  // Solid
  faArrowRight,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCircleNotch,
  faPaperPlane,
  faSpinner,
  faTimes,

  // Regular
  faEnvelope,
  faFileAlt,

  // Brands
  faFacebookF,
  faLinkedinIn,
  faTwitter
);
