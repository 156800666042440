import { AppProps } from "next/app";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Router from "next/router";
import useChangingRoutes from "src/hooks/useChangingRoutes";
import Spinner from "src/components/Spinner";

import "src/styles/globals.css";
import "src/components/LexicalRenderer/editorStyles.css";

/**
 * @note You change this configuration value to false so that the Font Awesome core SVG library
 * will not try and insert <style> elements into the <head> of the page. Next.js blocks this
 * from happening anyway so you might as well not even try.
 * @see https://fontawesome.com/docs/web/use-with/react/use-with
 */
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;

// icon library
import "src/icons/FontAwesome";
import { DefaultSeo } from "next-seo";
import DEFAULT_SEO from "../next-seo.config";
import Script from "next/script";

//if need to enable mock apis throughout the app
if (process.env.NEXT_PUBLIC_API_MOCKING === "enabled") {
  import("../__tests__/__apiMocks__");
}

const isProduction = process.env.NODE_ENV === "production";

if (isProduction) {
  try {
    Sentry.init({
      dsn: "https://07bca6bee649403c9260ddc03925c514@o54532.ingest.sentry.io/6600437",
      environment: process.env.NODE_ENV,
      release:
        process.env.REACT_APP_SENTRY_RELEASE || process.env.REACT_APP_VERSION,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(Router),
        }),
      ],
      maxBreadcrumbs: 25,
      normalizeDepth: 4,
    });
  } catch (e) {
    console.error("Sentry initialization has failed.");
  }
}

function AppRoot({ Component, pageProps }: AppProps) {
  useChangingRoutes();

  return (
    <>
      <DefaultSeo {...DEFAULT_SEO} />
      {isProduction && (
        <Script id="google-tag-manager" strategy="afterInteractive">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-TF856P5');`}
        </Script>
      )}
      <Component {...pageProps} />
      <Spinner />
      {isProduction && (
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TF856P5" height="0" width="0" style="display:none;visibility:hidden"></iframe>',
          }}
        />
      )}
    </>
  );
}

export default AppRoot;
